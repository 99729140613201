<template>
  <div>
    <van-dialog
      class="server_role_dialog"
      confirm-button-color="linear-gradient(to right, #ffa800, #ff3600)"
      theme="round-button"
      :value="show"
      confirm-button-text="确认"
      :close-on-click-overlay="true"
      :before-close="handleServerRoleDialogBeforeClose"
      @open="open"
    >
      <van-form>

        <van-field
          readonly
          clickable
          name="picker"
          label="游戏区服"
          placeholder="点击选择区服"
          :value="serverName"
          @click="showServerPicker = true"
          :rules="[{ required: true }]"
        />
        <van-popup
          v-model="showServerPicker"
          position="bottom"
          get-container="body"
        >
          <van-picker
            show-toolbar
            :columns="serverColumns"
            @confirm="onServerConfirm"
            @cancel="showServerPicker = false"
            :loading="serverListLoading"
          />
        </van-popup>

        <van-field
          readonly
          clickable
          name="picker"
          label="角色名"
          placeholder="点击选择角色"
          :value="roleName"
          @click="showRolePicker = true"
          :rules="[{ required: true }]"
        />

        <van-popup
          v-model="showRolePicker"
          position="bottom"
          get-container="body"
        >
          <van-picker
            show-toolbar
            :columns="roleColumns"
            @confirm="onRoleConfirm"
            @cancel="showRolePicker = false"
            :loading="roleListLoading"
          />
        </van-popup>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import { serverList, roleList } from "@/api/user"

export default {
  name: 'RoleSelect',
  props: {
    show: {
      default: true,
      type: Boolean
    },
    gameId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // 区服
      showServerPicker: false,
      serverListLoading: true,
      serverColumns: [],
      serverList: undefined,
      serverName: undefined,
      // 角色
      showRolePicker:false,
      roleListLoading: true,
      roleColumns: [],
      roleList: undefined,
      roleName: undefined,
      roleId: undefined,
    }
  },
  methods: {
    open() {
      this.serverName = undefined
      this.roleId = undefined
      this.roleName = undefined
      this.roleColumns = []
      this.serverColumns = []
      this.reqServerList()
    },
    // 请求区服列表
    reqServerList() {
      this.serverColumns = []
      serverList({ game_id: this.gameId }).then(response => {
        this.serverListLoading = false
        this.serverList = response
        response.forEach(element => {
          this.serverColumns.push(element.server)
        })
      })
      this.serverName = undefined
    },
    // 确认选择区服
    onServerConfirm(value) {
      this.serverName = value
      // 关闭区服选择器
      this.showServerPicker = false
      // 加载区服下角色
      this.reqRoleList()
      this.roleName = undefined
      this.roleId = undefined
    },
    // 请求角色列表
    reqRoleList() {
      this.roleColumns = []
      roleList({ game_id: this.gameId, server: this.serverName }).then(response => {
        this.roleListLoading = false
        this.roleList = response
        response.forEach(element => {
          this.roleColumns.push(element.role_name)
        })
      })
    },
    // 确认选择角色
    onRoleConfirm(value, index) {
      this.roleName = value // 角色名
      this.roleId = this.roleList[index]['id'] // 角色ID
      console.log('roleId', this.roleId)
      this.showRolePicker = false
    },
    handleServerRoleDialogBeforeClose(action, done) {
      if (action === 'confirm') {
        if (this.roleId && this.roleName) {
          this.$emit('success', { serverName: this.serverName, roleId: this.roleId, roleName: this.roleName })
        } else {
          done(false)
        }
      } else {
        this.$emit('close')
      }
      done()
    }
  }
}
</script>

<style>
</style>
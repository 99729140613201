<template>
  <div class="warpbg">
    <div class="user_header">
      <div v-if="!isLogin" class="user_login">您好，请<span @click="handleShowLogin">【登录】</span></div>
      <div v-if="isLogin" class="user_logout" >欢迎您，{{ account }} <span @click="handleLogout">【退出】</span></div>
    </div>
    <div class="number_text">第三届</div>
    <div class="frame_rank">
      <!-- <div class="box_rank"> -->
        <div class="rank_1">
          <img v-if="rankList[0]"
            class="rank_1_avatar"
            :src="rankList[0]['avatar']"
            alt=""
          >
          <div class="rank_text rank_1_text">
            <div
              class="rank_1_role rank_small_font"
              v-if="rankList[0]"
            >{{ rankList[0]['server'] }}</div>
            <div
              class="rank_1_money rank_small_font"
              v-if="rankList[0]"
            >{{ rankList[0]['role_name'] }}</div>
          </div>

        </div>
        <div class="rank_2-5 rank_2">
          <img v-if="rankList[1]"
            class="rank_2-5_avatar rank_2_avatar"
            :src="rankList[1]['avatar']"
            alt=""
          >
          <div class="rank_text rank_2-5_text ">
            <div
              class="rank_2-5_small_font"
              v-if="rankList[1]"
            >{{ rankList[1]['server'] }}</div>
            <div
              class="rank_2-5_money rank_2-5_small_font"
              v-if="rankList[1]"
            >{{ rankList[1]['role_name'] }}</div>
          </div>
        </div>
        <div class="rank_2-5 rank_3">
          <img v-if="rankList[2]"
            class="rank_2-5_avatar rank_3_avatar"
            :src="rankList[2]['avatar']"
            alt=""
          >
          <div class="rank_text rank_2-5_text ">
            <div
              class="rank_2-5_small_font"
              v-if="rankList[2]"
            >{{ rankList[2]['server'] }}</div>
            <div
              class="rank_2-5_money rank_2-5_small_font"
              v-if="rankList[2]"
            >{{ rankList[2]['role_name'] }}</div>
          </div>
        </div>
        <div class="rank_2-5 rank_4">
          <img v-if="rankList[3]"
            class="rank_2-5_avatar rank_4_avatar"
            :src="rankList[3]['avatar']"
            alt=""
          >
          <div class="rank_text rank_2-5_text ">
            <div
              class="rank_2-5_small_font"
              v-if="rankList[3]"
            >{{ rankList[3]['server'] }}</div>
            <div
              class="rank_2-5_money rank_2-5_small_font"
              v-if="rankList[3]"
            >{{ rankList[3]['role_name'] }}</div>
          </div>
        </div>
        <div class="rank_2-5 rank_5">
          <img v-if="rankList[4]"
            class="rank_2-5_avatar rank_5_avatar"
            :src="rankList[4]['avatar']"
            alt=""
          >
          <div class="rank_text rank_2-5_text ">
            <div
              class="rank_2-5_small_font"
              v-if="rankList[4]"
            >{{ rankList[4]['server'] }}</div>
            <div
              class="rank_2-5_money rank_2-5_small_font"
              v-if="rankList[4]"
            >{{ rankList[4]['role_name'] }}</div>
          </div>
        </div>

        <div class="rank_box_6-10">
          <div
            class="rank_6-10"
            v-for="(item, index) in rankList.slice(5, 10)"
            :key="index"
          >
            <div class="rank_6-10_text" v-if="item['money'] > 0">{{ item['server'] }}</div>
            <div class="rank_6-10_text" v-if="item['money'] > 0">{{ item['role_name'] }}</div>
            <div class="rank_6-10_wait_text" v-if="item['money'] <= 0">虚位以待</div>
          </div>
        </div>

        <div class="box_comment">
          <vue-seamless-scroll
            :data="comment"
            class="box_comment_warp"
            :class-option="scrollClassOption"
          >
          <div v-for="(item, index) in comment"
                :key="index" class="comment_text" v-text="item" >
          </div>
          </vue-seamless-scroll>
          <img class="btn_comment" src="../../assets/mrt/btn_comment.png" alt="" 
            @click="handleshowCommentInput" >
        </div>

        <!-- 上榜宣言提交 -->
        <van-dialog class="box_comment_input" v-model="showCommentInput" 
          :show-confirm-button="false"
          :close-on-click-overlay="true" >
          <!-- <div class="box_comment_input" > -->
          <div class="comment_input" contenteditable="true" @input="handleCommentInput($event)"></div>
          <img class="btn_comment" src="../../assets/mrt/btn_comment.png" alt="" 
            @click="handleSubmitComment"  >
          <!-- </div> -->
        </van-dialog>
        
      <!-- </div> -->
    </div>

    <!-- 用户弹幕 -->
    <div class="frame_user_barrage">
      <div class="user_barrage_content">
        <!-- <vue-babarrage
          ref="babarrage"
          :lanesCount="3"
          :isShow="true"
          :barrageList="barrageList"
          :loop="true"
          :maxWordCount="20"
          :throttleGap="20000"
        >
          <template v-slot:default="slotProps">
            <div class="barrage_cell">
              <div class="barrage_text"><span style="color:rgb(249, 209, 87);">{{slotProps.item.role_name}}</span>{{slotProps.item.msg}}</div>
            </div>
          </template>
        </vue-babarrage> -->
        <barrage
          ref="barrage"
          class="barrage"
          :barrage-list="barrageList"
          :speed="4"
          :loop="true"
          :background="'#525252'"
          :channels="3"/>
      </div>
      <input type="text" class="user_barrage_input" v-model="userBarrage" placeholder="发条弹幕，来和大神打声招呼~">
      <img class="btn_user_barrage_submit" src="../../assets/mrt/btn_user_barrage_submit.png"
        @click="handleSubmitBarrage" />
    </div>
    <div class="box_gift_rank">
      <div
        class="gift_rank_list"
        v-for="(item, index) in giftList"
        :key="index"
        v-show="index >= 0 && index <= 4"
      >
        <div
          class="gift_list"
          v-for="(gift, i) in item"
          :key="i"
          v-show="i >= 0 && i <= 4"
        >
          <div class="gift_img"><img :src="gift.prop_img"></div>
          
          <div class="prop_name">{{ gift.prop_name }}</div>
        </div>
      </div>

    </div>
    <div class="box_desc">
      <div class="text" style="float:left">活动说明:</div>
      <div v-html="desc" style="float:left"></div>
    </div>

    <user-login
      :show="loginShow"
      :logout="logout"
      @close="handleCloseLogin"
      @login-success="handleLoginSuccess"
      @logout-success="handleLogoutSuccess"
      @input="handleLoginInput"
     />

     <role-select
      :show="roleSelectShow"
      gameId="e76ff9acb8004cb491c7a09997ea3389"
      @close="handleRoleSelectClose"
      @success="handleRoleSelectSuccess"
     />
  </div>

    
</template>

<script>
import { Base64 } from 'js-base64'
import { parseQueryString } from '@/utils'
import { getMrt, rankComent, barrage, getBarrages } from '@/api/mrt'
import vueSeamlessScroll from 'vue-seamless-scroll'
import UserLogin  from "@/components/UserLogin"
import RoleSelect  from "@/components/RoleSelect"
import { checkAuth, getUsername } from "@/utils/auth"
import { getUser, setUser, removeUser } from "@/utils/user"
// import { vueBaberrage, MESSAGE_TYPE } from 'vue-baberrage'
import Barrage from 'vue-barrage'

export default {
  components: {
    vueSeamlessScroll, userLogin: UserLogin
    , roleSelect: RoleSelect,
    // , VueBabarrage: vueBaberrage,
    Barrage
  },
  data () {
    return {
      numberId: 0,
      isLogin: false,
      loginShow: false,
      account: undefined,
      logout: false,
      rankList: [],
      giftList: [],
      desc: undefined,
      remark: undefined,
      comment: [],
      scrollClassOption: {
        step:0.3,
        limitMoveNum: 1
      },
      showCommentInput: false,
      stateSubmitComment: true,
      rankComment: undefined,
      roleSelectShow: false,
      userInfo: undefined,
      // 用户输入弹幕
      userBarrage: undefined,
      stateSubmitBarrage: true,
      barrageList: [],
      barrageCurId: 0
    }
  },
  created () {
    const queryString = Base64.decode(this.$route.query.p)
    const queryParams = parseQueryString(queryString)
    this.numberId = queryParams.number

    this.initView()
    this.initUser()
    this.getMrtApi()
  },
  mounted() {
    this.setBarrageList()
  },
  methods: {
    initView () {
      let scale = 1.0 / window.devicePixelRatio;
      let text = `<meta name="viewport" content="width=device-width, initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale}, user-scalable=no">`;
      document.write(text);
      document.documentElement.style.fontSize = window.innerWidth / 7.5 + "px";
    },
    getMrtApi () {
      getMrt({ number_id: this.numberId }).then((res) => {
        this.desc = '<p>' + res.desc.replace(/\n/g, '</p><p>') + '</p>'
        this.remark = res.remark
        this.rankList = res.mrt_ranks
        this.comment = []
        this.rankList.forEach((val, index) => {
          this.rankList[index]['money'] = parseInt(val['money'])
          if (!val['avatar']) {
            this.rankList[index]['avatar'] = require('@/assets/mrt/rank_1_avatar.jpg')
          }
          if (val.comment) {
            this.comment.push(val.role_name + '：' + val.comment)
          }
          
        })
        console.log(this.rankList)
        this.giftList = res.mrt_gifts
        // this.comment = res.comment.split(/\n/g)
      })
    },
    initUser() {
      // 登录状态
      this.isLogin = checkAuth()
      if (this.isLogin) {
        this.account = getUsername()
        console.log(this.account)
        this.userInfo = getUser()
      }
    },
    checkRole() {
      if (!this.isLogin) {
        this.loginShow = true
      } else if (!getUser()) {
        this.roleSelectShow = true
      } else {
        return true
      }
      return false
    },
    handleShowLogin() {
      this.loginShow = true
    },
    handleLogout() {
      this.logout = true
      removeUser()
    },
    handleCloseLogin() {
      this.loginShow = false
    },
    handleLoginSuccess(user) {
      this.isLogin = true
      this.logout = false
      this.account = user.account
      this.$toast.success('登录成功')
      // 选择角色
      this.roleSelectShow = true
    },
    handleLogoutSuccess() {
      this.logout = false
      this.isLogin = false
      this.$toast.success('退出成功')
    },
    handleLoginInput(e) {
      if (e === false) {
        this.loginShow = false
      }
    },
    handleRoleSelectClose() {
      this.roleSelectShow = false
    },
    // 区服角色选择成功
    handleRoleSelectSuccess(role) {
      this.roleSelectShow = false
      console.log(role)
      setUser(role)
      this.userInfo = role
    },
    handleshowCommentInput() {
      if (this.checkRole()) {
        this.showCommentInput = true
      }
    },
    // 上榜宣言输入事件
    handleCommentInput(e) {
      if (e.target.innerText.length > 20) {
        this.$toast.fail('超出20个字数上限')
      } else {
        this.rankComment = e.target.innerText
      }
    },
    // 上榜宣言提交
    handleSubmitComment() {
      console.log(this.stateSubmitComment)
      if (!this.stateSubmitComment) {
        this.$toast.fail('发送频繁')
      } else if (!this.rankComment) {
        this.$toast.fail('请输入内容')
      } else {
        this.stateSubmitComment = false
        console.log(this.rankComment)
        rankComent({ number_id: this.numberId, role_id: this.userInfo.roleId, comment: this.rankComment })
        .then((response) => {
          this.$toast.success('留言成功')
          this.comment = []
          response.ranks.forEach(val => {
            if (val.comment) {
              this.comment.push(val.role_name + '：' + val.comment)
            }
          })
          this.showCommentInput = false
          this.stateSubmitComment = true
        }).catch(() => {
          this.stateSubmitComment = true
        })
      }
    },
    // 发送弹幕
    handleSubmitBarrage() {
      if (!this.checkRole()) {
        return ;
      } else if (!this.stateSubmitBarrage) {
        this.$toast.fail('发送频繁')
      } else if (!this.userBarrage) {
        this.$toast.fail('请输入内容')
      } else if (this.userBarrage.length > 10) {
        this.$toast.fail('超出10个字数上限')
      } else {
        this.stateSubmitBarrage = false
        barrage({ number_id: this.numberId, role_id: this.userInfo.roleId, comment: this.userBarrage })
        .then(response => {
          this.stateSubmitBarrage = true
          this.$toast.success('发送成功')
          console.log(response)
          // 添加弹幕
          this.setBarrageList([{
            role: { role_name: this.userInfo.roleName },
            comment: this.userBarrage
          }])
          this.userBarrage = undefined
        }).catch(() => {
          this.userBarrage = undefined
          this.stateSubmitBarrage = true
        })
      }
    },
    setBarrageList(barrages) {
      if (barrages) {
        barrages.forEach((val) => {
          this.barrageList.push({
            // id: this.barrageCurId++,
            // role_name: '*' + val.role.role_name.slice(-1),
            // msg: '：' + val.comment,
            // time: 5,
            // type: MESSAGE_TYPE.NORMAL
            content: '*' + val.role.role_name.slice(-1) + '：' + val.comment
          })
        })
      } else {
        this.barrageList = []
        getBarrages({ number_id: this.numberId }).then(response => {
          response.forEach((val, index) => {
            // this.barrageList.push({
            //   id: index + 1,
            //   role_name: '*' + val.role.role_name,
            //   msg: '：' + val.comment,
            //   time: 5,
            //   type: MESSAGE_TYPE.NORMAL
            // })

            console.log(index)
            this.barrageList.push({
              // id: index + 1,
              // role_name: '*' + val.role.role_name,
              content: '*' + val.role.role_name + '：' + val.comment,
              // time: 5,
              // type: MESSAGE_TYPE.NORMAL
            })
          })
          this.barrageCurId = response.length
        })
      }
      
		}
  }
}
</script>

<style>
html,
body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.warpbg {
  width: 100%;
  height: 32rem;
  background: url('../../assets/mrt/bg.jpg') top center no-repeat;
  background-size: contain;
  position: relative;
}
/* .warpbg .frame_rank,
.warpbg .frame_gift {
  float: left;
  width: 100%;
} */

.number_text {
  position:absolute;
  width: 0.38rem;
  height: 0.86rem;
  right: 1.80rem;
  top: 0.82rem;
  font-size: 0.25rem;
  color: rgb(249, 209, 87);
  font-weight: bold;
  text-align: center;
  line-height: 0.28rem;
}

.frame_rank {
  height: 9.23rem;
  position: absolute;
  top: 2.38rem;
  width: 100%;
  background: url('../../assets/mrt/role_rank_bg.png') top center no-repeat;
  /* height:15.20rem; */
  background-size: contain;
}
.frame_gift {
  /* background: url('../../assets/mrt/background_02.jpg') top center no-repeat; */
  background: url('../../assets/mrt/background_down_v1.gif') top center no-repeat;
  height:15.20rem;
  background-size: contain;
}
/* .box_rank {
  background: url('../../assets/mrt/user_rank_v1.gif') top center no-repeat;
  height: 8.46rem;
  background-size: contain;
  z-index: 100;
  position: relative;
} */
.rank_1 {
  position: absolute;
  top: 0.55rem;
  left: 2.88rem;
  width: 1.8rem;
  height: 2.3rem;
}
.rank_1_avatar {
  width: 1.06rem;
  height: 1.02rem;
  margin-top: 0.44rem;
  margin-left: 0.38rem;
  z-index: 200;
  float: left;
}
.rank_2-5 {
  position: absolute;
  width: 1.24rem;
  height: 1.59rem;
}
.rank_2-5_avatar {
  width: 0.74rem;
  height: 0.71rem;
  z-index: 200;
  float: left;
  margin-top: 0.3rem;
  margin-left: 0.26rem;
}
.rank_2 {
  top: 2.09rem;
  left: 0.9rem;
}
.rank_3 {
  top: 2.09rem;
  right: 0.87rem;
}
.rank_4 {
  top: 4.18rem;
  left: 0.9rem;
}
.rank_5 {
  top: 4.18rem;
  right: 0.87rem;
}
.rank_text {
  font-size: 0.3rem;
  color: rgb(249, 209, 87);
  text-align: center;
  display: block;
}
.rank_1_text {
  float: left;
  padding-top: 0.15rem;
  width: 1.8rem;
  height: 0.51rem;
}
.rank_small_font {
  position: absolute;
  left: 50%;
  display: block;
  white-space: nowrap;
  -webkit-transform: translate3d(-50%, 0, 0) scaleX(0.7) scaleY(0.7);
  transform: translate3d(-50%, 0, 0) scaleX(0.7) scaleY(0.7);
}
.rank_1_money {
  margin-top: 0.25rem;
}

.rank_2-5_text {
  float: left;
  width: 1.24rem;
  height: 0.35rem;
  padding-top: 0.05rem;
  font-size: 0.3rem;
  color: rgb(249, 209, 87);
  text-align: center;
  display: block;
}
.rank_2-5_small_font {
  position: absolute;
  left: 50%;
  display: block;
  white-space: nowrap;
  -webkit-transform: translate3d(-50%, 0, 0) scaleX(0.5) scaleY(0.5);
  transform: translate3d(-50%, 0, 0) scaleX(0.5) scaleY(0.5);
}
.rank_2-5_money {
  margin-top: 0.15rem;
}
.box_comment {
  background: url('../../assets/mrt/role_comment_bg.png') top center no-repeat;
  /* height: 2.84rem; */
  height: 2.40rem;
  width: 100%;
  z-index: 300;
  position: absolute;
  background-size: contain;
  margin: 0 auto;
  margin-top: 3.18rem;

  padding-top: 0.44rem;
}

.box_comment_warp {
  margin-top: 0.35rem;
  width: 2.17rem;
  height: 1.94rem;
  margin: 0 auto;
  overflow: hidden;
}

.comment_text {
  font-size: 0.25rem;
  color: rgb(249, 209, 87);
  /* line-height: 0.4rem; */
  /* -webkit-transform: translate3d(-00%, 0, 0) scaleX(0.8) scaleY(0.8);
  transform: translate3d(-00%, 0, 0) scaleX(0.8) scaleY(0.8); */
  /* display: flex; */

  justify-content: space-around;
  margin-bottom: 0.1rem;
}

.btn_comment {
  display: block;
  width: 1.17rem;
  height: 0.33rem;
  margin: 0 auto;
  margin-top: 0.02rem;
}
/* 玩家寄语输入 */
.box_comment_input {
  background: url('../../assets/mrt/role_comment.png') top center no-repeat;
  background-size: contain;
  width: 3.09rem;
  height: 2.75rem;
  /* margin-top: 3.08rem; */
  /* margin-left: 2.24rem; */
  /* position:absolute; */
  /* z-index: 400; */
}
.comment_input {
  width: 2.47rem;
  height: 1.18rem;
  margin-left: 0.31rem;
  margin-top: 0.75rem;
  font-size: 0.25rem;
  color: rgb(249, 209, 87);
}

.rank_box_6-10 {
  position: absolute;
  margin-top: 7.05rem;
  height: 1.07rem;
  /* width: 100%; */
  margin-left: 0.85rem;
}

.rank_6-10 {
  /* display: inline-block; */
  width: 0.52rem;
  height: 1.07rem;
  margin-right: 0.81rem;
  color: rgb(249, 209, 87);
  float: left;
}
.rank_6-10_text {
  float: left;
  margin: 0 auto;
  width: 0.26rem;
  height: 1.07rem;
  font-size: 0.2rem;

  display: block;
  -webkit-transform: translate3d(0, -10%, 0) scaleX(0.8) scaleY(0.8);
  transform: translate3d(0, -10%, 0) scaleX(0.8) scaleY(0.8);
}

.rank_6-10_wait_text {
  /* float: left; */
  padding-top: 0.2rem;
  margin: 0 auto;
  width: 0.26rem;
  height: 1.07rem;
  font-size: 0.2rem;
  display: block;
  transform: translate3d(0, -10%, 0) scaleX(0.9) scaleY(0.9);
}

/* 玩家弹幕 */
.frame_user_barrage{
  width: 7.32rem;
  height: 4.68rem;
  background: url('../../assets/mrt/user_barrage.png') top center no-repeat;
  background-size: contain;
  position:absolute;
  top: 11.5rem;
  left: 0.09rem;
  float: left;
}
.user_barrage_content {
  width: 6.46rem;
  height: 2.86rem;
  margin-top: 0.99rem;
  margin-left: 0.41rem;
  float:inherit;
  position: relative;
}

/* .barrage_cell {
  height: 0.5rem;
  border-radius: 1rem;
  background: rgb(82, 82, 82);
  width: auto;
  margin-left: 0.2rem;
}
.barrage_avatar {
  float: left;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  display: block;
  margin-left: 0.05rem;
  margin-top: 0.05rem;
}
.barrage_text {
  float: left;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  height: 0.5rem;
  width: auto;
  font-size: 0.25rem;
  color: white;
  line-height: 0.5rem;
} */

.z_barrage {
  width: 100% !important;
}

.user_barrage_input {
  float:inherit;
  width: 5.1rem;
  height: 0.38rem;
  margin-left: 0.47rem;
  margin-top: 0.02rem;
  font-size: 0.25rem;

  border:0;
  outline-style: none;
  padding: 0;
}
.btn_user_barrage_submit {
  float:inherit;
  display: block;
  width: 1.14rem;
  height: 0.42rem;
  margin-left: 0.05rem;
}

.box_gift_rank {
  position:absolute;
  top: 18.25rem;
  height: 5.9rem;
}
.gift_rank_list {
  /* width: 100%; */
  height: 0.7rem;
  margin-bottom: 0.5rem;
  padding-left: 0.2rem;
}

.gift_list {
  display: block;
  float: left;
  height: 0.7rem;
  width: 1.46rem;
  margin: 0 auto;
  text-align: center;
}
.gift_img {
  width: 0.5rem;
  height: 0.7rem;
  float: left;
}
.prop_name {
  float: left;
  color: white;
  font-size: 0.2rem;
  width: 0.96rem;
  /* white-space: nowrap; */
  -webkit-transform: translate3d(-12%, -15%, 0) scaleX(0.7) scaleY(0.7);
  transform: translate3d(-12%, -15%, 0) scaleX(0.7) scaleY(0.7);
}
.gift_list img {
  float: left;
  height: 0.42rem;
  width: 0.42rem;
  /* padding-left: 0.14rem; */
}

.box_desc {
  position:absolute;
  top: 24.2rem;
  margin-left: 0.2rem;
  width:96%;
  font-size: 0.25rem;
  color: rgb(249, 209, 87);
}
.box_desc .text {
  width: 7rem;
  float: left;
}

.box_desc p {
  margin: 0.15rem auto;
}
.frame_baberrage {
  position:absolute;
  top: 0.5rem;
  width: 100%;
  height: 3rem;
}
.user_header {
  position:absolute;
  right: 0.05rem;
  top: 0.22rem;
  font-size: 0.2rem;
  color: white;
  line-height: 0.2rem;
  text-shadow: 0 0 0.2rem black;
}
</style>